/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroAlt from 'components/elements/HeroAlt'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Images
import download from 'img/prof_download.svg'

// Third Party
import styled from 'styled-components'

const Year = styled.h3`
  font-size: ${props => props.theme.font.size.xxml};
  font-weight: ${props => props.theme.font.weight.l};
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.third};
`

const Content = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};
  p {
    margin-bottom: 0;
  }
`

const Publication = styled.div`
  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
  min-height: 100px;
  position: relative;
`

const Title = styled.div`
  padding-top: 20px;
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.contrast};
`

const Download = styled(CustomLink)`
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 24px;
  height: 24px;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroAlt fields={acf.banner} />

      <div style={{ paddingBottom: '150px', backgroundColor: '#F2F2F2' }}>
        <div className="container py-lg-5 py-3">
          <div className="row">
            {acf.years.map(y => (
              <div className="col-12 pb-lg-5 pb-3">
                <Year className="pb-4">{y.title}</Year>
                <div className="row">
                  {y.publications.map(p => (
                    <div className="col-sm-6 col-lg-4 pb-lg-3 pb-4">
                      <Publication>
                        <Content content={p.description} />
                        <Title>{p.person}</Title>
                        {p.paper && (
                          <Download external newPage to={p.paper.url.localFile.url}>
                            <img src={download} alt="" />
                          </Download>
                        )}
                      </Publication>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...writingPageFragment
    }
  }
`

export default PageTemplate
